import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import capabilityicon from "../../images/capability-organic-search-2.png";

import seostrategy from "../../images/search.png";
import seoaudits from "../../images/digital-audits.png";
import onpagesearchoptimisation from "../../images/on-page-search-optimisation.png";
import offpageoptimisation from "../../images/off-page-optimisation.png";
import contentcreation from "../../images/content-creation.png";
import searchreputationmanagement from "../../images/search-reputation-management.png";

import rowtwoheroleft from "../../images/organic-search-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/organic-search-row-two-hero-right.jpg";

import rowsixhero1 from "../../images/organic-search-row-six-hero-1.png";
import rowsixhero2 from "../../images/organic-search-row-six-hero-2.png";
import rowsixhero3 from "../../images/organic-search-row-six-hero-3.png";
import rowsixhero4 from "../../images/organic-search-row-six-hero-4.png";

import roweighthero from "../../images/organic-search-row-eight-hero.png";

import rowninehero from "../../images/organic-search-row-nine-hero.png";

import rowtenhero from "../../images/organic-search-row-ten-hero.png";

import "../../scss/capabilitypage.scss";

const OrganicSearch = ({ data }) => {
  const metadescription = "Next&Co's SEO services grow your brands organic search share of voice and provide value for your brands activity with measurable value, visit our site or call 1300 191 950";
  const metatitle = "SEO Agency Melbourne, SEO Company Melbourne - Next&Co";
  const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow/>
      <div className="capability-page-general organic-search-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Organic Search"
            description="Organic search - Grow your brands organic search share of voice"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={seostrategy}/>
                        </span>
                        <div className="content">
                          <h3>SEO Strategy</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={seoaudits} />
                        </span>
                        <div className="content">
                          <h3>SEO Audits</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={onpagesearchoptimisation} />
                        </span>
                        <div className="content">
                          <h3>On-page search <br/> optimisation</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={offpageoptimisation} />
                        </span>
                        <div className="content">
                          <h3>Off-page optimisation <br/> (link building)</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={contentcreation} />
                        </span>
                        <div className="content">
                          <h3>Content creation</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={searchreputationmanagement} />
                        </span>
                        <div className="content">
                          <h3>Search reputation <br/> management</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your brands organic search."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="Organic Search Audit"
            link2="/organic-search-audit"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Search - a key part of the customer journey</h4>
                  <p>Search is present throughout key moments of the customer journey. The cornerstone of a good organic search strategy is understanding what questions your brand audience are asking search engines along their journey, and basing the search strategy around this.</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img alt="" src={rowtwoheroleft} />
                </div>
                <div className="col">
                  <img alt="" src={rowtwoheroright} />
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row">
                    <div className="col">
                      <h4>Our organic search <br/> framework</h4>
                    </div>
                    <div className="col col-mobile">
                      <div className="scroll">
                        <div className="row">
                          <img className="show-desktop" alt="" src={rowsixhero1} />
                          <img className="show-desktop" alt="" src={rowsixhero2} />
                        </div>
                        <div className="row">
                          <img className="show-desktop" alt="" src={rowsixhero3} />
                          <img className="show-desktop" alt="" src={rowsixhero4} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-eight">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="col">
                  <div className="row">
                    <img alt="" src={roweighthero} />
                  </div>
                  <div className="col">
                    <h4>Keyword selection &amp; <br/> mapping</h4>
                    <p>Understanding the right keywords to target for your websites content is key.  Not all keywords are created equal with some having far more commercial value to your brand then others.  Our keyword selection methodology is designed to find the best phrases to target based on:</p>
                    <ul>
                      <li>Relevance to commercial goals</li>
                      <li>Keyword relevance</li>
                      <li>Searcher intent </li>
                      <li>Search volume</li>
                      <li>Competition levels</li>
                      <li>Keyword fit to websites information architecture</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your brands organic search."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="Organic search audit"
            link2="/organic-search-audit"
          />
          <section id="row-nine">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="col">
                  <div className="col">
                    <h4>On-page &amp; off-page</h4>
                    <p>We take a holistic approach to improving your brands organic search presence.  We reverse engineer the ranking factors of the top ranked websites and come up with a precise roadmap of activity to rank your brands domain for its target phrases. Optimising both your website (on-page SEO) as well as your back-link profile (off-page SEO) to generate results that are brand-safe and sustainable.</p>
                  </div>
                  <div className="row">
                    <img alt="" src={rowninehero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-ten">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="small-inner-container">
                    <div className="col">
                      <h4>Organic search that gets better day by day…</h4>
                      <p>We believe in rhythmic, continuous improvement – consistently moving resources and budget from non-converting activity to converting activity. <br/> With this system of continuous improvement, we help your search activity become better day by day, every day.</p>
                    </div>
                  </div>
                  <div className="col">
                    <img alt="" src={rowtenhero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
                    <SingleButton buttonText="Get in touch"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  );
};

export default OrganicSearch;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
